import React, { useEffect, useState } from 'react'

function Counter({filteredUsers}) {
  const [count, setCount] = useState(0);
    useEffect(() => {
    // Calculate the count based on the length of filteredUsers
    const newCount = filteredUsers.length;
    
    // Update the count state
    setCount(newCount);
  }, [filteredUsers]); // Re-run this effect when filteredUsers changes

  // return (
  //   <div>Вам подходит: <b>{count} </b>психологов</div>
  // )
}


export default Counter;