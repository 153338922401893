import React, { useContext } from "react";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";
import { MultiStepContext } from "../../StepContext";

export default function () {
  const { filteredUsers, resetSearch } = useContext(MultiStepContext);

   // Функция для перемешивания массива
   const shuffleArray = (array) => {
    // Копируем исходный массив
    const shuffledArray = [...array];
    
    // Перемешиваем элементы массива
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    
    return shuffledArray;
  };

  const shuffledUsers = shuffleArray(filteredUsers);

  console.log(shuffledUsers.length);


  return (
    <div>
      <div className="header">
        <Link to="https://lerba.kz/" target="_blank">
          <img src={logo} className="logo" alt="logo" />
        </Link>
      </div>
      <div className="titleContainer">
        <h2>Результаты поиска</h2>
        <Link to="/">
          <button onClick={() => resetSearch()}>Сбросить</button>
        </Link>
      </div>
      <div className="results">
        {shuffledUsers.map((user) => (
          <div className="resultItem">
            <div
              className="sliderImg"
              style={{
                position: "relative",
                background: `url(${user.img})`,
                backgroundSize: "cover",
              }}
            >
              <span className="experiens">Опыт: {user.experiens} {user.experiens<5 ? 'года' : 'лет'}</span>
            </div>
            <p>
              {user.name} {user.lastname}
            </p>
            <span className="price">{user.price} тг</span>
            <Link to={user.altegio_link/* + user.id*/} target="_blank">
              {" "}
              <button className="itemBtn">Записаться</button>
            </Link>
            <Link to={user.lerba_link/* + user.id*/} target="_blank">
              {" "}
              <button className="itemBtn more">Подробнее</button>
            </Link>
          </div>
        ))}
        
      </div>
      
      <div>
      {shuffledUsers.length === 1 && (
        <div className="catalogContainer">
          <Link to="https://lerba.kz/all" target="_blank">
            <button className="itemBtn catalog">Перейти в каталог</button>
          </Link>
        </div>
      )}
    </div>

      
    </div>
  );
}
