import Stepper from "@mui/material/Stepper";
import "./searchpage.css";
import logo from "../../logo.svg";
import Form3 from "../../components/forms/Form3";
import React, { useContext, useState } from "react";
import { MultiStepContext } from "../../StepContext";
import Form9 from "../../components/forms/Form9";

function Search() {
  const { currentStep, filteredUsers } = useContext(MultiStepContext);

  function showStep(step) {
    //console.log(step);
    switch (step) {
      case 3:
        return <Form3 />;
      case 9:
        return <Form9 />;
    }
  }
  //console.log("Context users: ", filteredUsers);
  return (
    <div className="container Form">
      <div className="header">
        <a target="_blank" href="https://lerba.kz/">
          <img src={logo} className="logo" alt="logo" />
        </a>
      </div>
      <Stepper activeStep={currentStep - 1} orientation="horizontal"></Stepper>
      {showStep(currentStep)}
    </div>
  );
}

export default Search;
