import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Specialists from "./pages/specialists/Specialists";
import Specialist from "./pages/specialist/Specialist";
import BusinessPage from "./pages/business/BusinessPage";
import Search from "./pages/search/Search";
import React from "react";
import StepContext from "./StepContext";
import HomeLayout from "./layouts/HomeLayout";
import Result from "./pages/search/Result";
import Test from "./pages/test/Test";

function App() {
  return (
    <StepContext>
      <BrowserRouter className="App">
        <div className="content">
          <Routes>
            {/* <Route element={<HomeLayout />}>
              <Route index element={<Home />} />
              <Route path="/psychologists" element={<Specialists />} />
              <Route path="/psychologists/:id" element={<Specialist />} />
              <Route path="/business" element={<BusinessPage />} />
            </Route> */}
            <Route path="/" element={<Search />} />
            <Route path="/result" element={<Result />} />
            {/* <Route path="/test" element={<Test />} /> */}
          </Routes>
        </div>
      </BrowserRouter>
    </StepContext>
  );
}

export default App;
