import React, { useContext, useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Counter from "./Counter";
import { MultiStepContext } from "../../StepContext";
import { Link } from "react-router-dom";
import { categories, users, spheres } from "../../data";
import { Accordion, AccordionDetails } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export default function Form3() {
  const { setStep, changeData, selectedCheckboxs, setSelectedCheckboxs } =
    useContext(MultiStepContext);

  // const usersThird = filteredUsers; // получаю список из второй формы
  const usersThird = users;
  const [thirdFilteredUsers, setThirdFilteredUsers] = useState(usersThird); // временное состояние
  //Выбранные чекбоксы
  const [catArray, setCatArray] = useState([]);

  function convertCheckboxes(array) {
    const catAr = array.map((box) => {
      const category = categories.find((cat) => cat.problems.includes(box));
      return category ? category.name : box;
    });
    return catAr.filter((x, i) => catAr.indexOf(x) === i);
  }

  function filterUsersByType(array) {
    return usersThird.filter((user) =>
      array.every((type) => user.directions.includes(type))
    );
  }

  function onChangeValue(e) {
    const selectedValue = e.target.value; // записываю выбранное значение

    const isChecked = selectedCheckboxs.includes(selectedValue);
    if (isChecked) {
      const updatedFilters = selectedCheckboxs.filter(
        (filter) => filter !== selectedValue
      );
      setSelectedCheckboxs(updatedFilters);
    } else {
      setSelectedCheckboxs([...selectedCheckboxs, selectedValue]);
    }
    setCatArray(convertCheckboxes(selectedCheckboxs));

    //const filtered3 = filterUsersByType(catArray);
    // setThirdFilteredUsers(filtered3);
  }

  useEffect(() => {
    const catArray = convertCheckboxes(selectedCheckboxs);
    setCatArray(catArray);

    const filteredUsers = filterUsersByType(catArray);
    setThirdFilteredUsers(filteredUsers);
  }, [selectedCheckboxs]);

  function handleReset() {
    setSelectedCheckboxs([]);
  }

  // console.log("Checkboxs: ", selectedCheckboxs);
  // console.log("catArray: ", convertCheckboxes(selectedCheckboxs));
  // console.log(" thirdFilteredUsers: ", thirdFilteredUsers);
  // console.log("usersThird: ", usersThird);
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  let button;
  if (thirdFilteredUsers.length == 0) {
    button = (
      <button disabled={selectedCheckboxs.length<1} onClick={() => changeData(9, thirdFilteredUsers)}>
        Продолжить
      </button>
    );
  } else {
    button = (
      <Link to="/result">
        <button disabled={selectedCheckboxs.length<1} onClick={() => changeData(4, thirdFilteredUsers)}>
          Продолжить
        </button>
      </Link>
    );
  }

  let newArr = [];
  return (
    <div className="container flexColumn">
      <a className="back" href="https://lerba.kz/" target="_blank" style={{fontSize: '14px', fontWeight: '600'}} /*onClick={() =>  window.location = 'https://lerba.kz/' setStep(2)}*/>
        Назад
      </a>
      <h2>Что вас беспокоит?</h2>
      {/* {selectedCheckboxs.map((box) => (
        <span>{box}</span>
      ))} */}
      <FormGroup>
        {spheres.map((sphere) => (
          <Accordion /*expanded*/ key={sphere.id}>
            <AccordionSummary>{sphere.name}</AccordionSummary>
            <AccordionDetails>
              <div className="Problems">
                {categories
                  .filter((category) => category.sphere === sphere.id)
                  .map((category) => category.problems)
                  .flat()
                  .map((problem) => (
                    <div className="itemProblem" style={filterUsersByType(convertCheckboxes([problem])).length<1 ? {display:'none'} : {display:'block'}}>
                      <FormControlLabel
                        key={problem.id}
                        control={<Checkbox />}
                        value={problem}
                        label={problem}
                        checked={selectedCheckboxs.includes(problem)}
                        onChange={(e) => onChangeValue(e)}
                        // disabled={filterUsersByType(convertCheckboxes([problem])).length<1}
                      />
                    </div>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </FormGroup>
      <div className="countContainer">
        <Counter filteredUsers={thirdFilteredUsers} />
        {button}
      </div>
    </div>
  );
}
