export const users = [
  {
    id: 1,
    name: "Даниил Нелюбин",
    gender: "man",
    img: "https://thumb.tildacdn.com/tild3063-6465-4032-a161-636632613535/-/format/webp/noroot.jpg",
    altegio_link: "https://n821403.alteg.io/",
    lerba_link: "https://lerba.kz/daniil_nelyubin",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 9.5,
    price: 20000,
    education: "ENU",
    type: ["online"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Алкоголизм",
      "Наркомания",
      "РПП",
      "Игровая зависимость",
      "Зависимость от кредитов",
      "Клептомания",
      "Эмоциональная зависимость от другого человека",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Синдром дисморфофобии (не в рамках РПП)",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "ПТСР",
      "Шизотипические расстройства и шизофрения",
      "ОКР",
      "Биполярное расстройство",
      "Депрессия",
      "Расстройства личности",
      "Послеродовая депрессия",
    ],
  },
  {
    id: 2,
    name: "Айгерим Нартаева",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild6238-6130-4763-a633-313761636666/-/format/webp/noroot.jpg",
    altegio_link: "https://n815985.alteg.io/",
    lerba_link: "https://lerba.kz/aygerim_nartaeva",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["online"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Разрыв отношений/развод",
      "Насилие в семье",
      "Утрата близкого человека",
    ],
  },
  {
    id: 3,
    name: "Венера Стадольник",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild3332-6237-4061-a533-346433666266/-/format/webp/noroot.jpg",
    altegio_link: "https://n821443.alteg.io/",
    lerba_link: "https://lerba.kz/venera_stadolnik",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 5,
    price: 16000,
    education: "ENU",
    type: ["personal", "online"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Эмоциональная зависимость от другого человека",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Семейная терапия пары",
      "Разрыв отношений/развод",
      "Насилие в семье",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "Проблемы в школе",
      "Буллинг",
      "Проблемы с обучением",
      "Детская или подростковая агрессия",
      "Нежелание ходить в школу",
      "ПТСР",
      "ОКР",
      "Депрессия",
      "Послеродовая депрессия",
    ],
  },
  // {
  //   id: 4,
  //   name: "Софья Чарлина",
  //   gender: "woman",
  //   img: "https://thumb.tildacdn.com/tild6634-6235-4234-b463-613236666434/-/format/webp/noroot.jpg",
  //   altegio_link: "https://n821444.alteg.io/",
  //   lerba_link: "https://lerba.kz/sofya_charlina",
  //   specialization: "Психолог, психотерапевт, гештальт-терапевт",
  //   about:
  //     "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
  //   experiens: 5,
  //   price: 14000,
  //   education: "ENU",
  //   type: ["personal"],
  //   target_audience: ["adult", "family", "couples", "child"],
  //   directions: [
  //     "Проблемы с мотивацией",
  //     "Апатия",
  //     "Проблемы с адаптацией в общество (новое место работы, переезд)",
  //     "Проблемы с концентрацией и вниманием",
  //     "Упадок сил",
  //     "Синдром эмоционального выгорания на работе",
  //     "Проблемы в отношениях с парнем/девушкой",
  //     "Проблемы в супружеской жизни",
  //     "Проблема в отношениях с родителями",
  //     "Комплексы в отношении себя или своего тела",
  //     "Проблемы с самооценкой",
  //     "ЛГБТ",
  //   ],
  // },
  {
    id: 5,
    name: "Олеся Сафиулина",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild3863-3566-4030-b666-373733383564/-/resize/378x/-/format/webp/noroot.jpg",
    altegio_link: "https://n821615.alteg.io/",
    lerba_link: "https://lerba.kz/olesya_safiulina",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 15,
    price: 18000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Сексоголизм",
      "Эмоциональная зависимость от другого человека",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и в  ниманием",
      "Перепады настроения",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Патологическая ревность",
      "Разрыв отношений/развод",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Отсутствие цели",
      "Биполярное расстройство",
      "Депрессия",
      "Послеродовая депрессия",
      "Самоповреждающее поведение",
      "ЛГБТ",
    ],
  },
  {
    id: 6,
    name: "Наталья Белянко",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild3635-3461-4630-a633-653937316362/-/resize/378x/-/format/webp/noroot.jpg",
    altegio_link: "https://n821619.alteg.io/",
    lerba_link: "https://lerba.kz/natalya_belyanko",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 14,
    price: 20000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Алкоголизм",
      "Наркомания",
      "Эмоциональная зависимость от другого человека",
      "Созависимость",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Семейная терапия пары",
      "Разрыв отношений/развод",
      "Насилие в семье",
      "Проблемы с самооценкой",
      "Отсутствие цели",
      "ПТСР",
      "ОКР",
      "Биполярное расстройство",
      "Депрессия",
    ],
  },
  {
    id: 7,
    name: "Инна Лаптева",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild6638-6336-4666-a336-323231386636/-/resize/378x/-/format/webp/noroot.jpg",
    altegio_link: "https://n821620.alteg.io/",
    lerba_link: "https://lerba.kz/inna_lapteva",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 5,
    price: 16000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Эмоциональная зависимость от другого человека",
      "Созависимость",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Патологическая ревность",
      "Разрыв отношений/развод",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "ОКР",
      "Депрессия",
      "Послеродовая депрессия",
    ],
  },
  {
    id: 8,
    name: "Лейла Абишева",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild6139-3837-4537-a661-353664356161/-/format/webp/noroot.jpg",
    altegio_link: "https://n821617.alteg.io/",
    lerba_link: "https://lerba.kz/leyla_abisheva",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 8,
    price: 14000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "ОКР",
      "Депрессия",
      "Послеродовая депрессия",
    ],
  },
  {
    id: 9,
    name: "Элина Чаплыгина",
    gender: "woman",
    img: "https://thumb.tildacdn.com/tild3561-6636-4336-b234-383931643763/-/resize/313x/-/format/webp/noroot.jpg",
    altegio_link: "https://n822418.alteg.io/",
    lerba_link: "https://lerba.kz/elina_chaplygina",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 4.3,
    price: 16000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Эмоциональная зависимость от другого человека",
      "Созависимость",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Патологическая ревность",
      "Семейная терапия пары",
      "Разрыв отношений/развод",
      "Насилие в семье",
      "Синдром дисморфофобии (не в рамках РПП)",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "Буллинг",
      "Детская или подростковая агрессия",
      "ПТСР",
      "ОКР",
      "Депрессия",
      "Послеродовая депрессия",
      "Ипохондрическое расстройство",
    ],
  },
  {
    id: 10,
    name: "Ягдар Туреханов",
    gender: "man",
    img: "https://static.tildacdn.com/tild3836-6130-4031-b934-303863356133/WhatsApp_Image_2024-.jpeg",
    altegio_link: "https://n1005814.alteg.io",
    lerba_link: "https://lerba.kz/yagdar_turekhanov",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 20,
    price: 20000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Отсутствие цели",
    ],
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 2,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 3,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 4,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 5,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 6,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
];

export const faqs = [
  {
    id: 1,
    header: "What is Lorem Ipsum?",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "Where does it come from?",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "Why do we use it?",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "Where can I get some?",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const spheres = [
  {
    id: 1,
    name: "СФЕРА ЗАВИСИМОСТЕЙ",
  },
  {
    id: 2,
    name: "ЭМОЦИОНАЛЬНО-ВОЛЕВЫЕ ТРУДНОСТИ",
  },
  {
    id: 3,
    name: "СЕМЕЙНАЯ СФЕРА И ОТНОШЕНИЯ",
  },
  {
    id: 4,
    name: "ОТНОШЕНИЕ К СЕБЕ И СВОЕМУ ТЕЛУ",
  },
  {
    id: 5,
    name: "ДЕТСКАЯ ПСИХОЛОГИЯ",
  },
  {
    id: 6,
    name: "ПСИХИАТРИЧЕСКИЕ ДИАГНОЗЫ",
  },
  {
    id: 7,
    name: "ЛГБТ",
  },
];
export const categories = [
  {
    id: 1,
    sphere: 1,
    name: "Алкоголизм",
    problems: [
      "Я чувствую, что злоупотребляю алкоголем",
      "Я пробовал отказаться от спиртного, но не получилось",
    ],
  },
  {
    id: 2,
    sphere: 1,
    name: "Наркомания",
    problems: [
      "мне кажется, что у меня проблема с наркотиками",
      "Я систематически употребляю наркотики (марихуана, мефедрон, трамадол и т.п.)",
    ],
  },
  {
    id: 3,
    sphere: 1,
    name: "РПП",
    problems: [
      "Я часто думаю о том, как ограничить себя в еде или сесть на диету",
      "бывают периоды, когда я вообще ничего не ем или после приемов пищи совершаю совершаю приступ рвоты",
      "Я виню себя за пищевые срывы",
    ],
  },
  {
    id: 4,
    sphere: 1,
    name: "Игровая зависимость",
    problems: [
      "Я думаю, что смогу заработать, поставив ставку или съездив в казино",
      "У меня есть долги (или были) после проигрышей в азартные игры",
      "Я играю в азартные игры и не могу остановиться",
    ],
  },
  {
    id: 5,
    sphere: 1,
    name: "Сексоголизм",
    problems: [
      "Я перестал/перестала получать удовольствие от секса, хотя его очень много в жизни",
      "Я чувствую, что слишком часто меняю половых партнеров и хочу с этим что-то сделать",
    ],
  },
  {
    id: 6,
    sphere: 1,
    name: "Зависимость от кредитов",
    problems: [
      "У меня много долгов, но я не могу перестать брать кредиты",
      "Значительная часть моей зарплаты уходит на погашение ежемесячного платежа",
    ],
  },
  {
    id: 7,
    sphere: 1,
    name: "Клептомания",
    problems: [
      "Иногда я могу украсть что-нибудь из магазина или дома друзей ради удовольствия",
      "У меня были проблемы из-за воровства в магазине или дома близких/друзей, но это меня не останавливает и меня это пугает",
    ],
  },
  {
    id: 8,
    sphere: 1,
    name: "Эмоциональная зависимость от другого человека",
    problems: [
      "Я не могу перестать думать о другом человеке",
      "Я часто смотрю о жизни другого человека в соцсетях, хотя мы с ней/ним давно расстались",
    ],
  },
  {
    id: 9,
    sphere: 1,
    name: "Созависимость",
    problems: [
      "Я устал/устала жить с зависимым человеком (алкоголизм, наркомания, игровая зависимость)",
      "Я не знаю что делать с тем, что мой близкий человека употребляет алкоголь/наркотики/играет в азартные игры",
    ],
  },
  {
    id: 10,
    sphere: 4,
    name: "Самоповреждающее поведение",
    problems: [
      "Я совершаю порезы своего тела",
      "У меня были суицидальные попытки, я хочу перестать их совершать",
      "Я недавно совершил/совершила суицидальную попытку и мне нужна помощь",
    ],
  },
  {
    id: 11,
    sphere: 2,
    name: "Проблемы с мотивацией",
    problems: [
      "Я не могу заставить себя что-либо делать",
      "Я не хочу учится или работать",
      "Я не хочу чем-либо заниматься",
    ],
  },
  {
    id: 12,
    sphere: 2,
    name: "Проблемы с агрессией",
    problems: [
      "Я не могу совладать со своей агрессией",
      "Я часто могу перестать контролировать себя и это переходит в конфликт или рукоприкладство",
      "Я могу избить другого человека (жену, мужа, ребенка, прохожего)",
    ],
  },
  {
    id: 13,
    sphere: 2,
    name: "Апатия",
    problems: [
      "Меня ничего не радует",
      "Мне ничего не приносит удовольствия",
      "Жизнь серая и бесперспективная",
    ],
  },
  {
    id: 14,
    sphere: 2,
    name: "Проблемы с адаптацией в общество (новое место работы, переезд)",
    problems: [
      "Я переехал/переехала из другой страны и не могу справиться с ощущением того, что я одинок",
      "Я вышел/вышла на новое место работы и мне трудно привыкнуть к новым людям и новому месту",
    ],
  },
  {
    id: 15,
    sphere: 2,
    name: "Проблемы с концентрацией и вниманием",
    problems: [
      "У меня не получается сосредоточиться на важных задачах или делах",
      "Во время разговора я могу думать о посторонних вещах",
    ],
  },
  {
    id: 16,
    sphere: 2,
    name: "Тревожно-фобические состояния",
    problems: [
      "У меня часто возникает необоснованное чувство тревоги",
      "Я испытываю сильный страх из-за каких-то конкретных событий",
    ],
  },
  {
    id: 17,
    sphere: 2,
    name: "Перепады настроения",
    problems: [
      "Я замечаю, что слишком резко меняется мое настроение от необоснованной радости до упадка и грусти",
      "Из-за перепадов в настроении мне трудно дается выполнение работы или учебы",
    ],
  },
  {
    id: 18,
    sphere: 2,
    name: "Прокрастинация",
    problems: [
      "Я постоянно откладываю дела на самый последний момент",
      "Я часто не успеваю сделать работу вовремя, в силу чего возникает чувство вины",
    ],
  },
  {
    id: 19,
    sphere: 2,
    name: "Упадок сил",
    problems: [
      "Я испытываю моральное, психологическое и физическое истощение",
      "Не хватает сил даже встать с кровати",
    ],
  },
  {
    id: 20,
    sphere: 2,
    name: "Синдром эмоционального выгорания на работе",
    problems: [
      "Любое упоминание о работе вызывает неприязнь или раздражение",
      "Когда я начинаю думать, что завтра на работу, появляется сильное нежелание туда идти, возникает упадок настроения и сил",
    ],
  },
  {
    id: 21,
    sphere: 2,
    name: "Утрата близкого человека",
    problems: ["Я не могу справиться со смертью близкого мне человека"],
  },
  {
    id: 22,
    sphere: 3,
    name: "Проблемы в отношениях с парнем/девушкой",
    problems: [
      "В отношениях с парнем/девушкой мне кажется, мы не пониманием друг друга, создается ощущение, что мы говорим на разных языках",
      "У нас частые конфликты из-за мелочей с моим (моей) парнем (девушкой)",
      "Я не знаю нужно ли мне продолжать отношения с парнем/девушкой",
    ],
  },
  {
    id: 23,
    sphere: 3,
    name: "Проблемы в супружеской жизни",
    problems: [
      "Я устал/устала от постоянных конфликтов в супружеской жизни",
      "Я не могу терпеть больше контроль со стороны супруга/супруги",
      "У нас с супругом/супругой давно не было секса",
    ],
  },
  {
    id: 24,
    sphere: 3,
    name: "Проблема в отношениях с родителями",
    problems: [
      "Родители постоянно сравнивали (сравнивают) меня с другими людьми, что очень раздражает и вызывает сильную обиду",
      "Родители не могут перестать меня контролировать, они принимают решения за меня (кем стать? Как жить? На кого учиться? И т.п.)",
      "Я боюсь говорить о проблемах своей маме (отцу), хотя многое меня не устраивает",
      "Я испытываю ненависть по отношении к родителям или родственникам",
    ],
  },
  {
    id: 25,
    sphere: 3,
    name: "Терапия состояния после измены",
    problems: [
      "Мне изменил мой партнер, я не могу справиться со своим состоянием",
    ],
  },
  {
    id: 26,
    sphere: 3,
    name: "Патологическая ревность",
    problems: [
      "Мой партнер постоянно меня преследует, следит за мной, контролирует, смотрит в телефон, подозревает в измене, я устал/устала от такой жизни",
      "Я подозреваю своего партнера в измене и есть постоянные мысли о том, что он/она делает, с кем встречается, куда ходит и т.д.",
    ],
  },
  {
    id: 27,
    sphere: 3,
    name: "Семейная терапия пары",
    problems: [
      "У нас проблема с партнером, которую мы бы хотели решить с семейным психологом вдвоем",
    ],
  },
  {
    id: 28,
    sphere: 3,
    name: "Разрыв отношений/развод",
    problems: [
      "Я не могу поверить и принять, что мы развелись и что больше не можем быть вместе",
      "На фоне развода и завершения отношений у меня начались психологические проблемы",
    ],
  },
  {
    id: 29,
    sphere: 3,
    name: "Насилие в семье",
    problems: [
      "Меня избивает муж/жена, я хочу найти способ — это прекратить",
      "Меня систематически избивали родители, я хочу справиться с этими воспоминаниями и болью",
    ],
  },
  {
    id: 30,
    sphere: 4,
    name: "Синдром дисморфофобии (не в рамках РПП)",
    problems: [
      "Я не принимаю себя или часть своего тела",
      "Мое тело вызывает у меня ненависть или отвращение",
    ],
  },
  {
    id: 31,
    sphere: 4,
    name: "Комплексы в отношении себя или своего тела",
    problems: [
      "Меня беспокоит мысль о размере или форме тех или иных частей своего тела ",
      "Мне стыдно показывать те или иные части своего тела другим людям",
      "Я постоянно испытываю стеснение из-за особенностей своего тела",
    ],
  },
  {
    id: 32,
    sphere: 4,
    name: "Проблемы с самооценкой",
    problems: [
      "Я постоянно ощущаю, что я хуже других людей",
      "Я хуже делаю что-либо, в сравнении с другими",
      "Я не решаюсь начать что-либо делать, т.к. искренне считаю, что я не справлюсь с этим",
    ],
  },
  {
    id: 33,
    sphere: 4,
    name: "Перфекционизм",
    problems: [
      "Я должен делать все идеально",
      "Я могу потратить много времени на какую-либо незначительную задачу, доводя ее до идеала",
    ],
  },
  {
    id: 34,
    sphere: 4,
    name: "Отсутствие цели",
    problems: [
      "Я не знаю, чем мне занять себя",
      "Я не знаю кем мне стать и какую профессию выбрать",
      "Я не вижу себя в будущем и испытываю сильную тревогу по этому поводу",
    ],
  },
  {
    id: 35,
    sphere: 5,
    name: "Проблемы в школе",
    problems: [
      "У моего ребенка (у меня) проблемы в школе (проблемы с учителем, проблемы с одноклассниками и т.п.)",
    ],
  },
  {
    id: 26,
    sphere: 5,
    name: "Буллинг",
    problems: [
      "Мой ребенок столкнулся с буллингом (унижениями, оскорблениями, притеснением) в школе",
    ],
  },
  {
    id: 37,
    sphere: 5,
    name: "Проблемы с обучением",
    problems: [
      "Мой ребенок не хочет учиться в школе",
      "Есть трудности в освоении тех или иных предметов",
      "Стоит на гране исключения из школы",
    ],
  },
  {
    id: 38,
    sphere: 5,
    name: "Детская или подростковая агрессия",
    problems: [
      "Ребенок постоянно дерется с другими детьми в школе",
      "Мой ребенок часто кричит на других детей в классе",
      "Мой ребенок может грубить учителям в школе, родителям или другим взрослым",
    ],
  },
  {
    id: 39,
    sphere: 5,
    name: "Нежелание ходить в школу",
    problems: [
      "Мой ребенок отказался ходить в школу, и я не знаю причины этого",
      "Мой ребенок отказался ходить в школу, я знаю причины, но ничего не могу сделать",
    ],
  },
  {
    id: 40,
    sphere: 5,
    name: "Непослушание",
    problems: [
      "Я не могу справится с подростком, он «отбился от рук»",
      "Моя дочь/мой сын перестали вообще что-либо делать и слушать меня",
      "У меня очень тяжелые и натянутые отношения со своим ребенком",
    ],
  },
  {
    id: 41,
    sphere: 6,
    name: "ПТСР",
    problems: [
      "Я не могу пережить травмирующее событие и постоянно об этом вспоминаю",
      "Я нахожусь в постоянном страхе и тревоге - мне не безопасно",
    ],
  },
  {
    id: 42,
    sphere: 6,
    name: "Шизотипические расстройства и шизофрения",
    problems: [
      "Мне поставили диагноз «шизофрения», я хочу научиться с этим жить",
      "Мне кажется у меня шизофрения",
    ],
  },
  {
    id: 43,
    sphere: 6,
    name: "ОКР",
    problems: [
      "Мне поставили диагноз обсессивно-компульсивное расстройство, я хочу начать дополнительно психотерапию",
      "Мне кажется у меня обсессивно-компульсивное расстройство",
    ],
  },
  {
    id: 44,
    sphere: 6,
    name: "Биполярное расстройство",
    problems: [
      "Мне поставили диагноз биполярное расстройство, я хочу дополнительно пройти курс психотерапии, чтобы научиться с этим справляться",
      "Мне кажется у меня биполярное расстройство",
    ],
  },
  {
    id: 45,
    sphere: 6,
    name: "Депрессия",
    problems: [
      "Мне поставили диагноз депрессия, я хочу попробовать изменить свое состояние, я очень от этого устал/устала",
      "Мне кажется у меня депрессия",
    ],
  },
  {
    id: 46,
    sphere: 6,
    name: "Послеродовая депрессия",
    problems: [
      "После рождения ребенка я ничего не хочу делать, я от всего устала",
      "Иногда возникает мысль, что это не мой ребенок и я не хочу с ним проводить время",
      "После родов я не могу нормально жить, иногда возникают мысли о самоубийстве",
    ],
  },
  {
    id: 47,
    sphere: 6,
    name: "Ипохондрическое расстройство",
    problems: [
      "Я думаю, что я точно болен каким-то страшным заболеванием",
      "Я очень много времени трачу на изучение разных болезней и неизбежно нахожу признаки болезней у себя",
      "Я сильно боюсь умереть от онкологии (вич, гепатита или другого заболевания), из-за чего я постоянно посещаю разных врачей, могу посещать одних и тех же врачей по несколько раз (вдруг они чего-то «не дообследовали»)",
    ],
  },
  {
    id: 48,
    sphere: 7,
    name: "ЛГБТ",
    problems: [
      "Я чувствую себя очень одиноким/одинокой из-за того, что не могу никому рассказать о своей сексуальной ориентации",
      "Я всю жизнь сталкивался/сталкивалась с осуждением со стороны общества и гомофобией, что привело меня к появлению больших проблем в самооценке и принятии себя",
      "Я ненавижу себя за то, что я испытываю влечение к представителям своего пола, я хотел/хотела бы пройти психотерапию, чтобы снизить эту ненависть к себе и найти способы принять себя",
      "Я чувствую себя некомфортно в своем теле и испытываю гендерную дисфорию",
      "Мне не подходит бинарная система разделения полов",
      "Я ненавижу себя за то, что мое тело недостаточно феминно/маскулинно",
      "Я никому не нужен потому что не такой/такая как большинство людей",
    ],
  },
];
