import React, { useState } from "react";

export const MultiStepContext = React.createContext();

const StepContext = ({ children }) => {
  const [currentStep, setStep] = useState(3);
  const [userData, setUserData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedCheckboxs, setSelectedCheckboxs] = useState([]);
  function changeData(step, users) {
    setStep(step);
    setFilteredUsers(users);
  }
  function resetSearch() {
    setFilteredUsers([]);
    setStep(3);
    setSelectedCheckboxs([]);
  }
  return (
    <MultiStepContext.Provider
      value={{
        currentStep,
        setStep,
        userData,
        setUserData,
        finalData,
        setFinalData,
        filteredUsers,
        setFilteredUsers,
        changeData,
        resetSearch,
        selectedCheckboxs,
        setSelectedCheckboxs,
      }}
    >
      {children}
    </MultiStepContext.Provider>
  );
};
export default StepContext;
